/*------------------------------------*\
  #Content builder text section
\*------------------------------------*/

.text-section {
    // @include text-style('400');
}

.text-section__headline {
    text-transform: none !important;
}