/*------------------------------------*\
  #accessibility
\*------------------------------------*/

// Utilities for improving accessibility with screen readers.

// Use sr-only to hide an element visually without hiding it from screen readers:
.sr-only,
.focus-only:not(:focus) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.focus-only {
    position: absolute;
}
