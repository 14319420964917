/*------------------------------------*\
  #MEDIA-BOX
\*------------------------------------*/

/**
 * Provide a media container in order to display media (usually images)
 * cropped to certain ratios and provide a placeholder box while images
 * are still loading
 *
 * 1. Set up a positioning context in which the image can sit.
 * 2. This is the crucial part: where the cropping happens.
 * 3. Show a background style before lazy images are shown
 */

// media box containing images or videos
// by default aspect ratio is defined by content
.media-box {
    position: relative; // [1]
    overflow: hidden; // [2]
    @include aspect-ratio($ratio-3\/2);

    img,
    video {
        -webkit-user-select: none;
        user-select: none;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
    }
}

.media-box--teaser {
    width: 100%;
    height: 100%;

    &:before {
        display: none;
    }
}

// media boxes with aspect ratios have a specific ratio

.media-box--ratio-2\/3 {
    @include aspect-ratio($ratio-2\/3);
};

.media-box--ratio-3\/4 {
    @include aspect-ratio($ratio-3\/4);
};

.media-box--ratio-1\/1 {
    @include aspect-ratio($ratio-1\/1);
};

.media-box--ratio-4\/3 {
    @include aspect-ratio($ratio-4\/3);
};

.media-box--ratio-3\/2 {
    @include aspect-ratio($ratio-3\/2);
};

.media-box--ratio-16\/9 {
    @include aspect-ratio($ratio-3\/2); // no real 16:9 on mobile

    @include respond-to(md) {
        @include aspect-ratio($ratio-16\/9);
    }
};