/*------------------------------------*\
  #LINKS
\*------------------------------------*/

a {
    text-decoration: none;
    color: inherit;

    @include hover() {
        // color: var(--text-highlight);
        span {
            text-decoration: underline;
            text-decoration-thickness: 10%;
        }
    }
}

.link--naked {
    span {
        text-decoration: none !important;
    }
}