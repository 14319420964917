/*------------------------------------*\
  #Newsletter
\*------------------------------------*/

.newsletter {
    margin-bottom: var(--size-800);

    label {
        @include text-style('400');
    }

    @include respond-to(md) {
        margin-bottom: var(--size-400);

        label {
            @include text-style('label');
        }
    }
}