.main-hero {
    // min-height: 100vh;
}

.main-hero__logo {
    display: grid;
    justify-content: center;
    position: sticky;
    transform: translate3d(0,0,0);
    height: var(--logo-h);
    top: calc(50vh - var(--logo-h) / 2);
    pointer-events: none;
    z-index: map-get($z-index, hero-logo);

    svg {
        width: min(36rem, 98vw);
        pointer-events: all;
    }

    @include respond-to(md) {
        // align indended svg with .container
        svg {
            margin: 0 -0.5rem;
            max-width: 100vw; // overrule reset
        }
    }
}

.main-hero__video-container {
    min-height: calc(86vh - var(--banner-h)); // assume real viewport height for iphones
    margin: calc(var(--logo-h) * -1) auto 0 auto; // slide below logo
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;

    // define real viewport height with new viewport values
    @supports (height: 100svh) {
        min-height: calc(var(--100vh) - var(--banner-h)*2);
    }

    // hide video controls if ios does not start video automatically
    video::-webkit-media-controls,
    video::-webkit-media-controls-play-button {
        display: none !important;
    }
}

.main-hero__video {
    padding: var(--banner-h) 0;
    width: min(80vw, 60vh);

    // @include respond-to(md) {
    //     width: min(80vw, 16rem);
    // }

    // @include respond-to(lg) {
    //     width: min(80vw, 18rem);
    // }
}
