.case-hero {
    height: var(--100vh);
    max-height: calc(var(--100vh) - (2 * var(--banner-h)) - var(--border-w));
    display: flex;
    flex-direction: column;
}

.case-hero__asset-container {
    flex: 1 1 auto;
    position: relative;
}

.case-hero__asset {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--bg-grey);
    user-select: none;
    -webkit-user-select: none;
}

.case-hero__headline {
    padding: .2rem 0; // magic number to visually center for mulitline headline
    min-height: var(--banner-h);
    display: flex;
    align-items: center;

    h2 {
        text-transform: none !important;
    }
}