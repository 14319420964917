/*------------------------------------*\
  #Main Footer
\*------------------------------------*/

.footer {
    // --flow-space: 0;
    text-transform: uppercase;
    @include text-style('label');
    padding-bottom: calc(var(--banner-h) + var(--border-w));
    display: flex;
    justify-content: center;

    &.footer--case-nav {
        border-top: var(--border-w) solid var(--border-default);
    }

    @include respond-to(lg) {
        border-top: var(--border-w) solid var(--border-default);
    }
}

.footer__inner {
    width: 100%;

    .footer--case-nav & {
        display: flex;
        justify-content: space-between;
        width: var(--container-w);
        height: var(--banner-h);
        margin-left: auto;
        margin-right: auto;
        align-items: center;
    }

    @include respond-to(lg) {
        width: var(--container-w);
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}

.footer__row {
    box-sizing: content-box;
    border-top: var(--border-w) solid var(--border-default);
    height: var(--banner-h);
    display: flex;
    align-items: center;

    @include respond-to(lg) {
        border: none;
    }
}

.footer__address,
.footer__links {
    width: var(--container-w);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;

    @include respond-to(lg) {
        width: 100%;
    }
}

.footer__address {
    @include respond-to(lg) {
        display: initial;
        justify-content: flex-start;
    }
}

.footer__links {
    @include respond-to(lg) {
        justify-content: flex-end;

        li:first-child {
            margin-right: 1rem;
        }
    }
}