/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

// Default button
//
// 1. Allow us to style box model properties.
// 2. Line different sized buttons up a little nicer.
// 3. Make buttons inherit font styles (often necessary when styling `input`s as buttons).
// 4. Reset/normalize some styles.
// 5. Force all button-styled elements to appear clickable.
// 6. Remove default appearance in some native occasions
// 7. Make buttons inherit font colors (often necessary when styling `a`s as buttons).
//

.btn {
    // Overwrites
    display: inline-block; // [1]
    vertical-align: middle; // [2]
    font: inherit; // [3]
    text-align: center; // [4]
    cursor: pointer; // [5]
    outline: none; // [4]
    border: none; // [4]
    background: none; // [4]
    appearance: none; // [7]
    white-space: nowrap; // [8]

    @include text-style('label');
}