/*------------------------------------*\
  #About
\*------------------------------------*/

.about {
    @include text-style('500');

    h2 {
        @include text-style('500');

        & + p {
            --flow-space: 0;
        }
    }

    @include respond-to(md) {
        @include text-style('600');

        h2 {
            @include text-style('600');
        }
    }
}