/*------------------------------------*\
  #Top and bottom banners
\*------------------------------------*/

.banner {
    @include text-style('banner');
    height: var(--banner-h);
    position: fixed;
    left: 0;
    right: 0;
    box-sizing: content-box;
    z-index: map-get($z-index, banner);
    background-color: var(--bg-default);
    overflow: hidden;
}

.banner--top {
    top: -1px; // remove blitzer
    border-bottom: var(--border-w) solid var(--border-default);
}

.banner--bottom {
    bottom: -1px; // remove blitzer
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: var(--border-w) solid var(--border-default);
}

.banner__usp {
    position: relative;
    height: var(--banner-h);
    animation: spin-words-in .3s $trans-func--ease-in-out 0.3s 1 both;

    span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        line-height: var(--banner-h);
        white-space: nowrap;
        overflow: hidden;
        opacity: 0;
        padding-left: var(--container-px);
        padding-right: var(--container-px);
        text-align: center;
        animation: spin-words-out .2s $trans-func--ease-in-out 1 forwards;
        transform-origin: top left;

        &.is-active {
            animation: spin-words-in .3s $trans-func--ease-in-out 1 forwards;
        }
    }
}