.case-teasers {

}

.case-teasers__inner {
    width: 100%;
}

.case-teasers__headline {
    text-transform: uppercase;
    @include text-style('label');
}

.case-teasers__list {
    display: grid;
    grid-template-columns: 1fr;
    touch-action: manipulation;

    @include respond-to(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include respond-to(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.case-teasers__item {
    position: relative;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - (2 * var(--banner-h)) + var(--size-400));

    // &:nth-child(n+10) {
    //     display: none;

    //     .show-more & {
    //         display: flex;
    //     }
    // }
}

// .case-teasers__item--show-more {
//     cursor: pointer;

//     .show-more &  {
//         display: none;
//     }
// }

.case-teasers__item--newsletter {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--bg-yellow);
    color: var(--text-inverted);
    padding: 10vw 4vw;
    @include text-style('500');

    h3 {
        text-transform: uppercase;
    }

    @include respond-to(md) {
        padding: 4vw 2VW;
    }
}

.case-teasers__more {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--text-default);
    text-transform: uppercase;
    pointer-events: none;
}

.case-teasers__label {
    z-index: map-get($z-index, case-label);
    background-color: var(--bg-default);
    @include text-style('label');
    padding: 0.15rem 2vw var(--border-w);
    text-transform: none;
    position: sticky;
    width: 100%;
    bottom: var(--banner-h);
    min-height: var(--label-h);
    transition: transform .15s $trans-func--ease-out,
                opacity .15s $trans-func--ease-out;
    color: var(--text-default);
    border-top: var(--border-w) solid var(--border-default);

    // .case-teasers__item--show-more & {
    //     display: none;
    // }

    @include respond-to(md) {
        @include text-style('label-sm');
        min-height: var(--label-h-sm);
        text-transform: none;
        padding: 0.15rem 1vw var(--border-w);
    }

    // @include respond-to(lg) {
    //     .case-teasers__item--show-more & {
    //         display: block;
    //     }
    // }
}

.case-teasers__teaser {
    width: 100%;
    height: 100%;
    padding-bottom: var(--size-400);
}

.case-teasers__teaser--shallow {

}

.case-teasers__asset {
    position: relative;
    height: calc(100% - var(--label-h));
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;

    @include respond-to(md) {
        height: calc(100% - var(--label-h-sm));
    }

    img,
    video {
        opacity: 0.85;
        transition: opacity $trans-time--xs $trans-func--ease-in-out;

        @include hover('.case-teasers__teaser') {
            opacity: 1;
        }

        // .case-teasers__item--show-more & {
        //     opacity: 0.3;
        // }

        // @include hover('.case-teasers__item--show-more .case-teasers__teaser') {
        //     opacity: 0.5;
        // }

        .case-teasers__teaser--shallow & {
            opacity: 1;
        }
    }
}