/*------------------------------------*\
  #main
\*------------------------------------*/

.main {
    margin-top: calc(var(--banner-h) - 1px);
    min-height: calc(var(--100vh) - (var(--banner-h)));
    // todo find another solution to have footer on bottom always?
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}