/*------------------------------------*\
  #Service
\*------------------------------------*/

.service {
    @include text-style('500');

    h2 {
        @include text-style('label');
        // margin-bottom: var(--size-400);
    }
}