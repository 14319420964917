/*------------------------------------*\
  #Media section gallery
\*------------------------------------*/


.gallery {

}

.gallery__image {
    cursor: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMuNTQgMTEuMjQiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0ibTEuMDMgMCAyLjUxIDUuNjItMi41MiA1LjYxLTEuMDEtLjEgMi4wMi01LjQ5TDAgLjExIDEuMDMgMFoiLz48L3N2Zz4=) 16 16,default;
}

.gallery__thumbnails {
    display: none;
    scrollbar-width: none;
    margin-top: 0 !important;
    padding: var(--flow-space) 0;

    &::-webkit-scrollbar {
        display: none;
    }

    .media-section__figure {
        grid-column: initial;
        grid-row: initial;
    }

    @include respond-to(md) {
        display: flex;
        column-gap: .2rem;
        grid-auto-flow:column;
        overflow-x: auto;
    }
}

.gallery__thumbnail {
    cursor: pointer;
    max-width: calc((100vw - (5*0.2rem))/6);
    min-width: calc((100vw - (7*0.2rem))/8);
    transform: scale(1);
    transition: transform .15s $trans-func--ease-out;

    &.is-active {
        transform: scale(.9);
    }
}

.gallery__indicator {
    margin-top: var(--size-200);
    display: flex;
    justify-content: flex-end;

    @include respond-to(md) {
        display: none;
    }
}