.section {
    margin-top: calc(var(--module-sm) - 1px);
    padding-bottom: calc(var(--module-sm) - 1px);
}

.section--extended-gap {
    margin-top: calc(var(--module-md) - 1px);
}

.section--basic {
    @include text-style('500');
}

.section--full-screen {
    @include respond-to(md) {
        min-height: calc(100vh - (2 * var(--module-sm)));
        display: flex;
        align-items: center;
    }
}

.section--mediaSection {
    .section--mediaSection + & {
        margin-top: 0;
    }
}