/*------------------------------------*\
  #Contact
\*------------------------------------*/

.contact {
    @include text-style('500');
    position: relative;

    h2 {
        @include text-style('label');
        // margin-bottom: var(--size-400);
    }
}